import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import * as fromReducer from './reducer';
import { TERRITORIES_TYPES } from '../../../components/modals/create-tenant/territories/enumerators';

import { enumerators as modalManagerEnumerators, selectors as modalManagerSelectors } from '../../modal-manager';

const getFormTenantFromReducer = get('form.tenant');

export const getIsLoading = state => fromReducer.getIsLoading(getFormTenantFromReducer(state));

export const getIdentification = state => fromReducer.getIdentification(getFormTenantFromReducer(state));

export const getContactPerson = state => fromReducer.getContactPerson(getFormTenantFromReducer(state));

export const getSubscription = state => fromReducer.getSubscription(getFormTenantFromReducer(state));

export const getTerritories = state => fromReducer.getTerritories(getFormTenantFromReducer(state));

export const getTerritoriesDescription = createSelector([getTerritories], territories => {
    return territories.reduce((acc, territory, index) => {
        let description = '';

        if (territory.level === TERRITORIES_TYPES.PARISH) {
            // eslint-disable-next-line max-len
            description += `${territory.parish.name} (TERRITORY_TYPE), ${territory.county.name}, ${territory.district.name}, ${territory.country.name}`;
        } else if (territory.level === TERRITORIES_TYPES.COUNTY) {
            description += `${territory.county.name} (TERRITORY_TYPE), ${territory.district.name}, ${territory.country.name}`;
        } else if (territory.level === TERRITORIES_TYPES.DISTRICT) {
            description += `${territory.district.name} (TERRITORY_TYPE), ${territory.country.name}`;
        } else if (territory.level === TERRITORIES_TYPES.COUNTRY) {
            description += `${territory.country.name} (TERRITORY_TYPE)`;
        }

        acc.push({
            level: territory.level,
            description,
            index
        });

        return acc;
    }, []);
});

export const getTenantToSubmit = createSelector(
    [getIdentification, getContactPerson, getTerritories, getSubscription, modalManagerSelectors.getCreateTenant],
    (identification, contactPerson, territories, subscription, createTenantModal) => {
        return {
            // identification fields
            ...Object.keys(identification).reduce((acc, identificationKey) => {
                if (
                    (identification[identificationKey] !== '' && createTenantModal?.data?.type !== modalManagerEnumerators.TYPE_EDIT) ||
                    (identification[identificationKey] !== '' &&
                        createTenantModal?.data?.type === modalManagerEnumerators.TYPE_EDIT &&
                        identificationKey !== 'slug')
                ) {
                    acc[identificationKey] = identification[identificationKey];
                }

                return acc;
            }, {}),

            contactUser: {
                // contact user fields
                ...Object.keys(contactPerson).reduce((acc, contactPersonKey) => {
                    if (contactPerson[contactPersonKey] !== '') {
                        acc[contactPersonKey] = contactPerson[contactPersonKey];
                    }

                    return acc;
                }, {})
            },
            territories: territories.map(territory => ({
                level: territory.level,
                id: territory[territory.level.toLowerCase()].id
            })),
            subscriptionStartDate: subscription.startDate,
            subscriptionEndDate: subscription.finishDate,
            ...(subscription.value ? { subscriptionValue: subscription.value } : {})
        };
    }
);
