import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import useStyles from './PageHeader.styles';

const propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node
};

const defaultProps = {
    children: <></>
};

const PageHeader = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            className={classes.root}
        >
            <Typography
                className={classes.title}
                variant="h2"
            >
                {title}
            </Typography>
            <Box
                className={classes.actionContainer}
                flexShrink={0}
            >
                {children}
            </Box>
        </Box>
    );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
