import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    territory: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '2rem'
    },
    removeIcon: {
        padding: theme.spacing(1)
    }
}));
