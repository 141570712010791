const reducerName = '@cityoo-backoffice/form/tenant';

export const RESET = `${reducerName}/RESET`;

export const SUBMIT_IDENTIFICATION_INFO = `${reducerName}/SUBMIT_IDENTIFICATION_INFO`;
export const SUBMIT_CONTACT_PERSON_INFO = `${reducerName}/SUBMIT_CONTACT_PERSON_INFO`;
export const SUBMIT_SUBSCRIPTION = `${reducerName}/SUBMIT_SUBSCRIPTION`;
export const SUBMIT_TERRITORY = `${reducerName}/SUBMIT_TERRITORY`;
export const REMOVE_TERRITORY = `${reducerName}/REMOVE_TERRITORY`;

export const SUBMIT_TENANT_START = `${reducerName}/SUBMIT_TENANT_START`;
export const SUBMIT_TENANT_SUCCESS = `${reducerName}/SUBMIT_TENANT_SUCCESS`;
export const SUBMIT_TENANT_FAIL = `${reducerName}/SUBMIT_TENANT_FAIL`;

export const GET_TENANT_START = `${reducerName}/GET_TENANT_START`;
export const GET_TENANT_SUCCESS = `${reducerName}/GET_TENANT_SUCCESS`;
export const GET_TENANT_FAIL = `${reducerName}/GET_TENANT_FAIL`;
