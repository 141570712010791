import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MediaQuery from '../../utils/media-query';
import useStyles from './BaseStepper.styles';

const propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    activeStep: PropTypes.number.isRequired,
    goToStep: PropTypes.func.isRequired,
    nonLinear: PropTypes.bool
};

const defaultProps = {
    nonLinear: false
};

const BaseStepper = ({ steps, activeStep, goToStep, nonLinear }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Stepper nonLinear={nonLinear} activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step completed={activeStep > index} key={label}>
                        <StepButton onClick={() => goToStep(index)}>
                            <MediaQuery
                                Desktop={index === activeStep ? t(`${label}`).toUpperCase() : ''}
                                Tablet={index === activeStep ? t(`${label}`).toUpperCase() : ''}
                                Mobile=""
                            />
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

BaseStepper.propTypes = propTypes;
BaseStepper.defaultProps = defaultProps;

export default BaseStepper;
