import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getFormatedDate } from '../../../utils/date';

import {
    actions as activityLogsActions,
    selectors as activityLogsSelectors,
    enumerators as activityLogsEnumerators
} from '../../../redux/spatial/activity-logs';

import PaginationControls from '../../../components/pagination-controls';
import List, { ListItem, ListItemHeader } from '../../../components/list';
import Loading from '../../../components/base-loading';
import ConfirmModal from '../../../components/modals/confirm-modal';

import { statusOptions } from './enumerators';
import useStyles from './Monitoring.styles';

const Monitoring = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [statusValue, setStatusValue] = useState(-1);

    const activityLogs = useSelector(activityLogsSelectors.getActivityLogs);
    const isLoadingActivityLogs = useSelector(activityLogsSelectors.getIsLoading);

    const headers = [
        { key: 'timestamp', title: t('monitoring.timestamp'), width: '20%' },
        { key: 'tenant', title: t('monitoring.entity'), width: '10%' },
        { key: 'status', title: t('monitoring.status'), width: '20%' },
        { key: 'description', title: t('monitoring.description') }
    ];

    const handleChangeStatus = useCallback(
        event => {
            setStatusValue(event.target.value);
        },
        [setStatusValue]
    );

    const onPageChange = useCallback(
        (_ev, page) => {
            if (page !== activityLogs.page) {
                dispatch(activityLogsActions.setPage(page));
            }
        },
        [dispatch, activityLogs.page]
    );

    useEffect(() => {
        dispatch(
            activityLogsActions.getLogs({
                page: activityLogs.page,
                limit: activityLogs.limit,
                status: statusValue >= 0 ? statusValue : null
            })
        );
    }, [dispatch, activityLogs.page, activityLogs.limit, statusValue]);

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" className={classes.title}>
                    {t('monitoring.monitoring')}
                </Typography>
                <FormControl className={classes.dropdownRoot}>
                    <InputLabel>{t('monitoring.status')}</InputLabel>
                    <Select value={statusValue} onChange={handleChangeStatus}>
                        {statusOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {t(option.text)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <List headers={headers}>
                {isLoadingActivityLogs ? (
                    <div className={classes.loadingContainer}>
                        <Loading />
                    </div>
                ) : (
                    activityLogs.rows &&
                    activityLogs.rows.map(item => (
                        <ListItem key={`list-logs-${item.id}`}>
                            <ListItemHeader headers={headers}>
                                <Typography key="timestamp" variant="body1">
                                    {getFormatedDate(item.createdAt)}
                                </Typography>
                                <Typography key="tenant" variant="body1">
                                    {item.tenantId ?? t('monitoring.notDetected')}
                                </Typography>
                                <Box key="status" display="flex">
                                    <Box
                                        className={classes.statusCell}
                                        style={{
                                            backgroundColor: activityLogsEnumerators.getStatusesData(item.status).color
                                        }}
                                    >
                                        <Typography variant="body1">{t(activityLogsEnumerators.getStatusesData(item.status).label)}</Typography>
                                    </Box>
                                </Box>
                                <Typography key="description" variant="body1">
                                    {item.description}
                                </Typography>
                            </ListItemHeader>
                        </ListItem>
                    ))
                )}
            </List>
            {!isLoadingActivityLogs ? <PaginationControls pages={activityLogs.pages} page={activityLogs.page} onChange={onPageChange} /> : null}
            <ConfirmModal />
        </div>
    );
};

export default Monitoring;
