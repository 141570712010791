const baseUrl = '/tenants';

const find = (client, params) => {
    const url = `${baseUrl}/paged`;
    return client.get(url, { params });
};

const get = (client, tenantId) => {
    const url = `${baseUrl}/${tenantId}`;
    return client.get(url, {});
};

const insert = (client, data) => {
    const url = `${baseUrl}`;

    return client.post(url, data);
};

const update = (client, tenantId, data) => {
    const url = `${baseUrl}/${tenantId}`;

    return client.put(url, data);
};

const block = (client, tenantId) => {
    const url = `${baseUrl}/${tenantId}/block`;

    return client.put(url);
};

const unblock = (client, tenantId) => {
    const url = `${baseUrl}/${tenantId}/unblock`;

    return client.put(url);
};

export default client => ({
    find: params => find(client, params),
    get: tenantId => get(client, tenantId),
    insert: data => insert(client, data),
    update: (tenantId, data) => update(client, tenantId, data),
    block: tenantId => block(client, tenantId),
    unblock: tenantId => unblock(client, tenantId)
});
