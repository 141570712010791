import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 8rem'
    },
    title: {
        color: theme?.palette?.text?.link,
        marginTop: theme.spacing(1)
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    submitButton: {
        backgroundColor: theme.palette.background.cityoo,
        color: theme.palette.background.white
    },
    territoriesContainer: {
        width: '25rem',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem'
    },
    submitButtonForward: {
        marginLeft: theme.spacing(1)
    }
}));
