import React from 'react';
import PropTypes from 'prop-types';

import ListHeader from './list-header';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node.isRequired
};

const List = ({ headers, children }) => {
    return (
        <>
            <ListHeader headers={headers} />
            {children}
        </>
    );
};

List.propTypes = propTypes;

export default List;
