const reducerName = '@cityoo-backoffice/modalManager';

export const RESET = `${reducerName}/RESET`;

export const OPEN_CREATE_TENANT = `${reducerName}/OPEN_CREATE_TENANT`;
export const CLOSE_CREATE_TENANT = `${reducerName}/CLOSE_CREATE_TENANT`;

export const OPEN_SHOW_TENANT = `${reducerName}/OPEN_SHOW_TENANT`;
export const CLOSE_SHOW_TENANT = `${reducerName}/CLOSE_SHOW_TENANT`;

export const OPEN_CONFIRM_MODAL = `${reducerName}/OPEN_CONFIRM_MODAL`;
export const CLOSE_CONFIRM_MODAL = `${reducerName}/CLOSE_CONFIRM_MODAL`;

export const OPEN_UNFULFILLED_MODAL = `${reducerName}/OPEN_UNFULFILLED_MODAL`;
export const CLOSE_UNFULFILLED_MODAL = `${reducerName}/CLOSE_UNFULFILLED_MODAL`;
