const host = process.env.REACT_APP_API_BASE_URL;

if (!host) {
    throw new Error('API_BASE_URL env var not set');
}

const config = {
    apiBaseUrl: `${host}/api/v1`,
    apiExternalUrl: `${host}/api/external/v1`,
    TOKENS: 'TOKENS',
    notificationsDefaultTime: 6000
};

export default config;
