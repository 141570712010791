import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@material-ui/core';

import useStyles from './ListItemHeader.styles';
import { LIST_ITEM_HEADER } from '../enumerators';

const propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    children: PropTypes.node.isRequired
};

const ListItemHeader = ({ headers, children }) => {
    const classes = useStyles();

    const getHeaderWidthByKey = key => headers.find(h => h.key === key)?.width;

    const getStyles = width => (width ? { maxWidth: width } : {});

    return (
        <Box display="flex" alignItems="center" className={classes.root}>
            {children &&
                children.map(child => (
                    <Box
                        key={child.key}
                        className={classnames(classes.childElement, {
                            fixed: !!getHeaderWidthByKey(child.key)
                        })}
                        style={getStyles(getHeaderWidthByKey(child.key))}
                    >
                        {child}
                    </Box>
                ))}
        </Box>
    );
};

ListItemHeader.displayName = LIST_ITEM_HEADER;
ListItemHeader.propTypes = propTypes;

export default ListItemHeader;
