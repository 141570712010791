import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';
import { Formik, Form } from 'formik';

import BaseInput from '../../../base-input';
import { actions as formTenantActions, selectors as formTenantSelectors } from '../../../../redux/form/tenant';
import { enumerators as modalManagerEnumerators, selectors as modalManagerSelectors } from '../../../../redux/modal-manager';

import useStyles from './Identification.styles';
import identificationHelper from './helper';

const propTypes = {
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onModifiedStep: PropTypes.bool.isRequired
};

const Identification = ({ onNext, onClose, onModifiedStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state props
    const identificationInfo = useSelector(formTenantSelectors.getIdentification);
    const createTenantModal = useSelector(modalManagerSelectors.getCreateTenant);

    const handleSubmitIdentificationForm = useCallback(
        values => {
            dispatch(formTenantActions.submitIdentificationInfo(values));
            onNext();
        },
        [dispatch, onNext]
    );

    return (
        <Formik
            initialValues={identificationInfo}
            onSubmit={handleSubmitIdentificationForm}
            validationSchema={identificationHelper.getValidationSchema()}
        >
            {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                <Form>
                    <div className={classes.container}>
                        <Typography variant="h3" className={classes.title}>
                            {t('identification').toUpperCase()}
                        </Typography>
                        {Object.keys(values).map(fieldKey => (
                            <BaseInput
                                key={fieldKey}
                                label={t(snakeCase(fieldKey))}
                                id={fieldKey}
                                name={fieldKey}
                                required={
                                    identificationHelper.formOptions.hasOwnProperty(fieldKey) && identificationHelper.formOptions[fieldKey].required
                                }
                                multiline={
                                    identificationHelper.formOptions.hasOwnProperty(fieldKey) && identificationHelper.formOptions[fieldKey].multiline
                                }
                                disabled={createTenantModal?.data?.type === modalManagerEnumerators.TYPE_EDIT && fieldKey === 'slug'}
                                value={values[fieldKey]}
                                helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                                error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                                onChange={e => {
                                    onModifiedStep(true);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />
                        ))}
                    </div>
                    <div className={classes.actions}>
                        <Button onClick={onClose} color="secondary" variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleSubmit} variant="outlined" className={classes.submitButton}>
                            {t('forward')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

Identification.propTypes = propTypes;

export default Identification;
