/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkSdkMiddleware from '../utils/thunk-sdk-middleware';

import accountReducer from './account';
import formReducer from './form';
import modalManagerReducer from './modal-manager';
import notificationsReducer from './notifications';
import territoriesReducer from './territories';
import tenantsReducer from './tenants';
import spatialReducer from './spatial';

const rootReducer = combineReducers({
    account: accountReducer,
    form: formReducer,
    modalManager: modalManagerReducer,
    notifications: notificationsReducer,
    territories: territoriesReducer,
    tenants: tenantsReducer,
    spatial: spatialReducer
});

const configureStore = (initialState = {}) => {
    const middlewares = [thunkSdkMiddleware()];

    const enhancers = [applyMiddleware(...middlewares)];

    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

    return store;
};

export default configureStore();
