import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    parishes: []
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PARISHES_SUCCESS:
        case actionTypes.GET_PARISHES_FAIL:
            return false;
        case actionTypes.GET_PARISHES_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const parishes = (state = initialState.parishes, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_PARISHES_SUCCESS:
            return action.payload;
        case actionTypes.RESET:
            return initialState.parishes;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getParishes = state => get(state, 'parishes', initialState.parishes);

const reducer = combineReducers({
    isLoading,
    parishes
});

export default reducer;
