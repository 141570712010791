import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        background: theme.palette.background.white,
        padding: '2rem',
        marginBottom: '2rem',
        borderRadius: '0.625rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)'
    }
}));
