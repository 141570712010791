import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import {
    selectors as modalManagerSelectors,
    actions as modalManagerActions,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';

import BaseDialog from '../../base-dialog';
import Resume from '../create-tenant/resume';

import useStyles from './TenantDetails.styles';

const TenantDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const showTenant = useSelector(modalManagerSelectors.getShowTenant);

    const handleClose = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.SHOW_TENANT));
    }, [dispatch]);

    if (!showTenant.isOpen) {
        return null;
    }

    return (
        <BaseDialog styleClasses={{ root: classes.root }} disableBackdropClick open onClose={handleClose}>
            <Box paddingLeft="2rem">
                <Resume />
            </Box>
        </BaseDialog>
    );
};

export default TenantDetails;
