import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { snakeCase } from 'lodash';

import BaseDatePicker from '../../../base-date-picker';
import BaseInput from '../../../base-input';

import { actions as formTenantActions, selectors as formTenantSelectors } from '../../../../redux/form/tenant';

import useStyles from './Subscription.syles';
import subscriptionHelper from './helper';

const propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onModifiedStep: PropTypes.func.isRequired
};

const Subscription = ({ onNext, onPrevious, onModifiedStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state props
    const subscription = useSelector(formTenantSelectors.getSubscription);

    const handleDate = useCallback(
        type => date => {
            dispatch(
                formTenantActions.submitSubscription({
                    ...subscription,
                    startDate: type === 'startDate' ? moment(date) : subscription.startDate,
                    finishDate: type === 'finishDate' ? moment(date) : subscription.finishDate
                })
            );
        },
        [dispatch, subscription]
    );

    const handleSubmitForm = useCallback(
        values => {
            dispatch(
                formTenantActions.submitSubscription({
                    ...subscription,
                    ...values
                })
            );
            onNext();
        },
        [dispatch, subscription, onNext]
    );

    return (
        <Formik initialValues={{ value: subscription.value }} onSubmit={handleSubmitForm} validationSchema={subscriptionHelper.getValidationSchema()}>
            {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                <Form>
                    <div className={classes.container}>
                        <Typography variant="h3" className={classes.title}>
                            {t('subscription').toUpperCase()}
                        </Typography>
                        <BaseDatePicker
                            maxDate={subscription.finishDate}
                            value={subscription.startDate}
                            onChange={handleDate('startDate')}
                            label={t('start_date')}
                        />
                        <BaseDatePicker
                            minDate={subscription.startDate}
                            value={subscription.finishDate}
                            onChange={handleDate('finishDate')}
                            label={t('finish_date')}
                        />
                        {Object.keys(values).map(fieldKey => (
                            <BaseInput
                                key={fieldKey}
                                label={t(snakeCase(fieldKey))}
                                id={fieldKey}
                                name={fieldKey}
                                required={
                                    subscriptionHelper.formOptions.hasOwnProperty(fieldKey) && subscriptionHelper.formOptions[fieldKey].required
                                }
                                multiline={
                                    subscriptionHelper.formOptions.hasOwnProperty(fieldKey) && subscriptionHelper.formOptions[fieldKey].multiline
                                }
                                type={fieldKey === 'value' ? 'number' : 'text'}
                                value={values[fieldKey]}
                                helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                                error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                                onKeyDown={subscriptionHelper.blockInvalidChar}
                                onChange={e => {
                                    onModifiedStep(true);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />
                        ))}
                    </div>
                    <div className={classes.actions}>
                        <Button onClick={onPrevious} color="secondary" variant="contained">
                            {t('previous')}
                        </Button>
                        <Button onClick={handleSubmit} variant="outlined" className={classes.submitButton}>
                            {t('forward')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

Subscription.propTypes = propTypes;

export default Subscription;
