const baseUrl = '/spatial';

const find = (client, params) => {
    const url = `${baseUrl}/activity-logs/paged`;

    return client.get(url, { params });
};

export default client => ({
    find: params => find(client, params)
});
