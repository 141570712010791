import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { snakeCase } from 'lodash';

import { getDate } from '../../../../utils/date';

import { actions as formTenantActions, selectors as formTenantSelectors } from '../../../../redux/form/tenant';
import { enumerators as modalManagerEnumerators, selectors as modalManagerSelectors } from '../../../../redux/modal-manager';

import FloatingLabel from '../../../floating-label/FloatingLabel';
import TerritoryItem from '../territories/territory-item';
import Loading from '../../../base-loading';

import useStyles from './Resume.styles';

const propTypes = {
    onPrevious: PropTypes.func,
    withSteper: PropTypes.bool
};

const defaultProps = {
    withSteper: false,
    onPrevious: null
};

const Resume = ({ onPrevious, withSteper }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // state props
    const createTenantModal = useSelector(modalManagerSelectors.getCreateTenant);
    const identification = useSelector(formTenantSelectors.getIdentification);
    const contactPerson = useSelector(formTenantSelectors.getContactPerson);
    const tenantToSubmit = useSelector(formTenantSelectors.getTenantToSubmit);
    const subscription = useSelector(formTenantSelectors.getSubscription);
    const territoriesDescription = useSelector(formTenantSelectors.getTerritoriesDescription);
    const isLoadingProject = useSelector(formTenantSelectors.getIsLoading);

    const handleSubmitTenant = useCallback(() => {
        if (createTenantModal?.data?.type === modalManagerEnumerators.TYPE_EDIT) {
            dispatch(formTenantActions.updateTenant(tenantToSubmit, createTenantModal?.data?.id));
        } else {
            dispatch(formTenantActions.insertTenant(tenantToSubmit));
        }
    }, [dispatch, tenantToSubmit, createTenantModal]);

    if (isLoadingProject) {
        return (
            <div className={classes.loadingContainer}>
                <Loading />
            </div>
        );
    }

    return (
        <>
            <div className={withSteper ? classes.container : ''}>
                <Typography variant="h3" className={classes.title}>
                    {t('identification').toUpperCase()}
                </Typography>
                {identification &&
                    Object.keys(identification).map(fieldKey =>
                        identification[fieldKey] ? (
                            <FloatingLabel
                                spacing="SMALL"
                                key={`identification-${fieldKey}`}
                                label={t(snakeCase(fieldKey))}
                                content={identification[fieldKey]}
                            />
                        ) : null
                    )}
                <Typography variant="h3" className={classes.title}>
                    {t('contact_person').toUpperCase()}
                </Typography>
                {contactPerson &&
                    Object.keys(contactPerson).map(fieldKey =>
                        contactPerson[fieldKey] ? (
                            <FloatingLabel
                                spacing="SMALL"
                                key={`contact-${fieldKey}`}
                                label={t(snakeCase(fieldKey))}
                                content={contactPerson[fieldKey]}
                            />
                        ) : null
                    )}
                <Typography variant="h3" className={classes.title}>
                    {t('subscription').toUpperCase()}
                </Typography>
                {subscription &&
                    Object.keys(subscription).map(fieldKey =>
                        subscription[fieldKey] ? (
                            <FloatingLabel
                                spacing="SMALL"
                                key={`subscription-${fieldKey}`}
                                label={t(snakeCase(fieldKey))}
                                content={fieldKey.toLowerCase().includes('date') ? getDate(subscription[fieldKey]) : subscription[fieldKey]}
                            />
                        ) : null
                    )}
                <Typography variant="h3" className={classes.title}>
                    {t('territories').toUpperCase()}
                </Typography>
                {territoriesDescription.map(territory => (
                    <TerritoryItem readOnly={!withSteper} key={territory.index} territory={territory} />
                ))}
            </div>
            <div className={classes.actions}>
                {withSteper && (
                    <>
                        <Button onClick={onPrevious} color="secondary" variant="contained">
                            {t('previous')}
                        </Button>
                        <Button onClick={handleSubmitTenant} color="primary" variant="contained" className={classes.submitButton}>
                            {t('conclude')}
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

Resume.propTypes = propTypes;
Resume.defaultProps = defaultProps;

export default Resume;
