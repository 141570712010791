/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import ROUTES from '../../utils/routes';

// styles
import useStyles from './Layout.styles';

// components
import Header from '../header';
import Sidebar from '../sidebar';
import { selectors as accountSelectors } from '../../redux/account';

// pages
import TenantsPage from '../../pages/tenants';
import SatellitePage from '../../pages/satellite';
import MapPage from '../../pages/map';
import DashboardPage from '../../pages/dashboard';
import PaymentPage from '../../pages/payment';
import Error from '../../pages/error';

// context
import { useLayoutState } from '../../context/LayoutContext';

const Layout = ({ history }) => {
    const classes = useStyles();

    // global
    const layoutState = useLayoutState();

    const isAuthenticated = useSelector(accountSelectors.isAuthenticated);

    return (
        <div className={classes.root}>
            {isAuthenticated ? (
                <>
                    <Header history={history} />
                    <Sidebar />
                    <div
                        className={classnames(classes.content, {
                            [classes.contentShift]: layoutState.isSidebarOpened
                        })}
                    >
                        <div className={classes.fakeToolbar} />
                        <Switch>
                            <Route exact path={ROUTES.HOME} render={() => <Redirect to={ROUTES.TENANTS} />} />
                            <Route path={ROUTES.MAP} component={MapPage} />
                            <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
                            <Route path={ROUTES.TENANTS} component={TenantsPage} />
                            <Route path={ROUTES.PAYMENT} component={PaymentPage} />
                            <Route path={ROUTES.SATELLITE} component={SatellitePage} />
                            <Route component={Error} />
                        </Switch>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default withRouter(Layout);
