import activityLogs from './activity-logs';
import auth from './auth';
import territories from './territories';
import tenants from './tenants';
import spatial from './spatial';

export default client => ({
    activityLogs: activityLogs(client),
    auth: auth(client),
    territories: territories(client),
    tenants: tenants(client),
    spatial: spatial(client)
});
