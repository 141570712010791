import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.white,
        padding: '2rem',
        borderRadius: '0.625rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)'
    },
    title: {
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    actionContainer: {
        marginLeft: '1rem'
    }
}));

export default useStyles;
