const enumerators = {
    TYPE_CREATE: 'create',
    TYPE_EDIT: 'edit',
    CREATE_TENANT: 'CREATE_TENANT',
    SHOW_TENANT: 'SHOW_TENANT',
    CONFIRM_MODAL: 'CONFIRM_MODAL',
    UNFULFILLED: 'UNFULFILLED'
};

export default enumerators;
