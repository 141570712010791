import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import {
    Edit as EditIcon,
    Block as BlockIcon,
    Add as AddIcon,
    Visibility as ShowIcon,
    SettingsBackupRestore as SettingsBackupRestoreIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { actions as modalManagerActions, enumerators as modalManagerEnumerators } from '../../redux/modal-manager';
import { actions as tenantsActions, selectors as tenantsSelectors } from '../../redux/tenants';
import { actions as formTenantActions } from '../../redux/form/tenant';
import { actions as selectedOptionsTerritoriesActions } from '../../redux/territories/selected-options';

import PageHeader from '../../components/page-header';
import FloatingLabel, { CONTENT_TYPES } from '../../components/floating-label';
import CreateTenant from '../../components/modals/create-tenant';
import CollapsibleList, { CollapsibleListItem, CollapsibleListItemHeader, CollapsibleListItemBody } from '../../components/collapsible-list';
import PaginationControls from '../../components/pagination-controls';
import ActionsGroup, { ActionGroupItem } from '../../components/actions-group';
import Loading from '../../components/base-loading';
import ConfirmModal from '../../components/modals/confirm-modal/ConfirmModal';
import TenantDetails from '../../components/modals/tenant-details/TenantDetails';
import UnfulfilledModal from '../../components/modals/unfulfilled-modal';

import useStyles from './Tenants.styles';

const Tenants = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const headers = [
        { key: 'name', title: t('tenant_name') },
        { key: 'email', title: t('email'), hideOnOpen: true },
        { key: 'phoneNumber', title: t('phone_number'), hideOnOpen: true },
        { key: 'actions', title: t('options'), width: '20%' }
    ];

    const tenants = useSelector(tenantsSelectors.getTenants);
    const isLoading = useSelector(tenantsSelectors.getIsLoading);

    const handleCreateEntity = useCallback(() => {
        dispatch(modalManagerActions.openModal(modalManagerEnumerators.CREATE_TENANT, { type: modalManagerEnumerators.TYPE_CREATE }));
        dispatch(selectedOptionsTerritoriesActions.reset());
    }, [dispatch]);

    useEffect(() => {
        dispatch(tenantsActions.getTenants({ limit: tenants.limit, page: tenants.page }));
    }, [dispatch, tenants.limit, tenants.page]);

    const onShowTenant = useCallback(
        (ev, tenant) => {
            ev.stopPropagation();

            dispatch(formTenantActions.getTenant(tenant));
            dispatch(selectedOptionsTerritoriesActions.reset());
            dispatch(modalManagerActions.openModal(modalManagerEnumerators.SHOW_TENANT));
        },
        [dispatch]
    );

    const onEditTenant = useCallback(
        (ev, tenant) => {
            ev.stopPropagation();

            dispatch(formTenantActions.getTenant(tenant));
            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CREATE_TENANT, { type: modalManagerEnumerators.TYPE_EDIT, id: tenant.id })
            );
            dispatch(selectedOptionsTerritoriesActions.reset());
        },
        [dispatch]
    );

    const onBlockTenant = useCallback(
        (ev, tenant) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(tenantsActions.blockTenant(tenant.id)),
                            variant: 'outlined',
                            text: 'forward'
                        }
                    ],
                    title: t('block_entity'),
                    message: `${t('are_you_sure_block_entity')} ${tenant.legalName}?`
                })
            );
        },
        [dispatch, t]
    );

    const onUnblockTenant = useCallback(
        (ev, tenant) => {
            ev.stopPropagation();

            dispatch(
                modalManagerActions.openModal(modalManagerEnumerators.CONFIRM_MODAL, {
                    buttons: [
                        {
                            action: () => dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CONFIRM_MODAL)),
                            color: 'secondary',
                            variant: 'contained',
                            text: 'cancel'
                        },
                        {
                            action: () => dispatch(tenantsActions.unblockTenant(tenant.id)),
                            variant: 'outlined',
                            text: 'forward'
                        }
                    ],
                    title: t('unblock_entity'),
                    message: `${t('are_you_sure_unblock_entity')} ${tenant.legalName}?`
                })
            );
        },
        [dispatch, t]
    );

    const onPageChange = useCallback(
        (ev, page) => {
            if (page !== tenants.page) {
                dispatch(tenantsActions.setPage(page));
            }
        },
        [dispatch, tenants.page]
    );

    return (
        <div>
            <PageHeader title={t('pages.tenants')}>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleCreateEntity}>
                    {t('new_tenant')}
                </Button>
            </PageHeader>

            <CollapsibleList headers={headers}>
                {isLoading ? (
                    <div className={classes.loadingContainer}>
                        <Loading />
                    </div>
                ) : (
                    tenants?.rows.map(tenant => (
                        <CollapsibleListItem key={tenant.id}>
                            <CollapsibleListItemHeader headers={headers}>
                                <Typography key="name" variant="body1" className={classnames({ [classes.disabled]: !tenant.isActive })}>
                                    {tenant.name}
                                </Typography>
                                <Typography key="email" variant="body1" className={classnames({ [classes.disabled]: !tenant.isActive })}>
                                    {tenant.contactUser?.email}
                                </Typography>
                                <Typography key="phoneNumber" variant="body1" className={classnames({ [classes.disabled]: !tenant.isActive })}>
                                    {tenant.contactUser?.phoneNumber}
                                </Typography>
                                <ActionsGroup key="actions">
                                    <ActionGroupItem key="show" title={t('show')} row={tenant} onClick={onShowTenant}>
                                        <ShowIcon fontSize="small" className={classnames({ [classes.disabled]: !tenant.isActive })} />
                                    </ActionGroupItem>
                                    <ActionGroupItem key="edit" title={t('edit')} row={tenant} onClick={onEditTenant}>
                                        <EditIcon fontSize="small" className={classnames({ [classes.disabled]: !tenant.isActive })} />
                                    </ActionGroupItem>
                                    {tenant.isActive ? (
                                        <ActionGroupItem key="block" title={t('block')} row={tenant} onClick={onBlockTenant}>
                                            <BlockIcon fontSize="small" />
                                        </ActionGroupItem>
                                    ) : (
                                        <ActionGroupItem key="unblock" title={t('unblock')} row={tenant} onClick={onUnblockTenant}>
                                            <SettingsBackupRestoreIcon fontSize="small" className={classes.disabled} />
                                        </ActionGroupItem>
                                    )}
                                </ActionsGroup>
                            </CollapsibleListItemHeader>
                            <CollapsibleListItemBody>
                                <Box display="flex" flexDirection="row">
                                    <Box flexGrow={1}>
                                        <FloatingLabel label={t('legal_name')} content={tenant.legalName} />
                                        <FloatingLabel label={t('vat_number')} content={tenant.vatNumber} />
                                        <FloatingLabel label={t('street')} content={tenant.street} />
                                        <FloatingLabel label={t('town')} content={tenant.town} />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <FloatingLabel label={t('contact_person')} content={tenant.contactUser?.fullName} />
                                        <FloatingLabel label={t('phone_number')} content={tenant.contactUser?.phoneNumber} />
                                        <FloatingLabel label={t('email')} content={tenant.contactUser?.email} contentType={CONTENT_TYPES.EMAIL} />
                                    </Box>
                                </Box>
                            </CollapsibleListItemBody>
                        </CollapsibleListItem>
                    ))
                )}
            </CollapsibleList>

            <PaginationControls pages={tenants.pages} page={tenants.page} onChange={onPageChange} />

            <CreateTenant />
            <TenantDetails />
            <ConfirmModal />
            <UnfulfilledModal />
        </div>
    );
};

export default Tenants;
