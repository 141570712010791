import { DEFAULT_ACTION } from '../constants';
import enumerators from './enumerators';

import * as actionTypes from './actionTypes';

export const openModal = (modal, payload) => {
    switch (modal) {
        case enumerators.CREATE_TENANT:
            return { type: actionTypes.OPEN_CREATE_TENANT, payload };
        case enumerators.SHOW_TENANT:
            return { type: actionTypes.OPEN_SHOW_TENANT, payload };
        case enumerators.CONFIRM_MODAL:
            return { type: actionTypes.OPEN_CONFIRM_MODAL, payload };
        case enumerators.UNFULFILLED:
            return { type: actionTypes.OPEN_UNFULFILLED_MODAL, payload };
        default:
            return DEFAULT_ACTION;
    }
};

export const closeModal = modal => {
    switch (modal) {
        case enumerators.CREATE_TENANT:
            return { type: actionTypes.CLOSE_CREATE_TENANT };
        case enumerators.SHOW_TENANT:
            return { type: actionTypes.CLOSE_SHOW_TENANT };
        case enumerators.CONFIRM_MODAL:
            return { type: actionTypes.CLOSE_CONFIRM_MODAL };
        case enumerators.UNFULFILLED:
            return { type: actionTypes.CLOSE_UNFULFILLED_MODAL };
        default:
            return DEFAULT_ACTION;
    }
};

export const resetModal = modal => {
    switch (modal) {
        case enumerators.RESET_MODALS:
            return { type: actionTypes.RESET };
        default:
            return DEFAULT_ACTION;
    }
};
