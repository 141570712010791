import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import BaseDialog from '../../base-dialog';
import BaseStepper from '../../base-stepper';
import Identification from './identification';
import ContactPerson from './contact-person';
import Territories from './territories';
import Subscription from './subscription';
import Resume from './resume';
import Loading from '../../base-loading';

import { selectors as formTenantSelectors } from '../../../redux/form/tenant';
import {
    actions as modalManagerActions,
    selectors as modalManagerSelectors,
    enumerators as modalManagerEnumerators
} from '../../../redux/modal-manager';

import createTenantHelper from './helper';
import useStyles from './CreateTenant.styles';

const CreateTenant = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state
    const [activeStep, setActiveStep] = useState(0);
    const [modifiedStep, setModifiedStep] = useState(false);

    // state props
    const isLoadingForm = useSelector(formTenantSelectors.getIsLoading);
    const createTenantModal = useSelector(modalManagerSelectors.getCreateTenant);

    useEffect(() => {
        if (!createTenantModal.isOpen) {
            setActiveStep(0);
            setModifiedStep(false);
        }
    }, [createTenantModal, setActiveStep]);

    const handleGoToStep = useCallback(
        index => {
            setActiveStep(index);
        },
        [setActiveStep]
    );

    const onCloseDialog = useCallback(() => {
        dispatch(modalManagerActions.closeModal(modalManagerEnumerators.CREATE_TENANT));
    }, [dispatch]);

    const onPrevious = useCallback(() => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
            setModifiedStep(false);
        }
    }, [activeStep, setActiveStep, setModifiedStep]);

    const onNext = useCallback(() => {
        if (activeStep < createTenantHelper.getSteps().length) {
            setActiveStep(activeStep + 1);
            setModifiedStep(false);
        }
    }, [activeStep, setActiveStep, setModifiedStep]);

    const onModifiedStep = useCallback(() => setModifiedStep(true), [setModifiedStep]);

    const renderStepComponent = () => {
        if (isLoadingForm) {
            return (
                <div className={classes.loadingContainer}>
                    <Loading />
                </div>
            );
        }

        switch (activeStep) {
            case 0:
                return <Identification onModifiedStep={onModifiedStep} onNext={onNext} onClose={onCloseDialog} />;
            case 1:
                return <ContactPerson onModifiedStep={onModifiedStep} onNext={onNext} onPrevious={onPrevious} />;
            case 2:
                return <Territories onModifiedStep={onModifiedStep} onNext={onNext} onPrevious={onPrevious} />;
            case 3:
                return <Subscription onModifiedStep={onModifiedStep} onNext={onNext} onPrevious={onPrevious} />;
            case 4:
                return <Resume withSteper onPrevious={onPrevious} />;
            default:
                return <Resume withSteper onPrevious={onPrevious} />;
        }
    };

    return (
        <BaseDialog
            open={createTenantModal.isOpen}
            onClose={onCloseDialog}
            title={createTenantModal?.data?.type === modalManagerEnumerators.TYPE_EDIT ? t('edit_tenant') : t('new_tenant')}
        >
            <div>
                <BaseStepper
                    nonLinear={createTenantModal?.data?.type === modalManagerEnumerators.TYPE_EDIT && !modifiedStep}
                    steps={createTenantHelper.getSteps()}
                    activeStep={activeStep}
                    goToStep={handleGoToStep}
                />
                {renderStepComponent()}
            </div>
        </BaseDialog>
    );
};

export default CreateTenant;
