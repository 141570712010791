import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    selectedCountry: {
        name: ''
    },
    selectedDistrict: {
        name: ''
    },
    selectedCounty: {
        name: ''
    },
    selectedParish: {
        name: ''
    }
};

export const selectedCountry = (state = initialState.selectedCountry, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_COUNTRY: {
            return action.payload;
        }
        case actionTypes.RESET:
            return initialState.selectedCountry;
        default:
            return state;
    }
};

export const selectedDistrict = (state = initialState.selectedDistrict, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_DISTRICT: {
            return action.payload;
        }
        case actionTypes.RESET:
        case actionTypes.RESET_SELECTED_DISTRICT:
            return initialState.selectedDistrict;
        default:
            return state;
    }
};

export const selectedCounty = (state = initialState.selectedCounty, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_COUNTY: {
            return action.payload;
        }
        case actionTypes.RESET:
        case actionTypes.RESET_SELECTED_COUNTY:
            return initialState.selectedCounty;
        default:
            return state;
    }
};

export const selectedParish = (state = initialState.selectedParish, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_PARISH: {
            return action.payload;
        }
        case actionTypes.RESET:
        case actionTypes.RESET_SELECTED_PARISH:
            return initialState.selectedParish;
        default:
            return state;
    }
};

export const getSelectedCountry = state => get(state, 'selectedCountry', initialState.selectedCountry);

export const getSelectedDistrict = state => get(state, 'selectedDistrict', initialState.selectedDistrict);

export const getSelectedCounty = state => get(state, 'selectedCounty', initialState.selectedCounty);

export const getSelectedParish = state => get(state, 'selectedParish', initialState.selectedParish);

const reducer = combineReducers({
    selectedCountry,
    selectedDistrict,
    selectedCounty,
    selectedParish
});

export default reducer;
