import moment from 'moment';

const baseUrl = '/spatial';

const createChangeDetected = (client, params) => {
    const { apiKey, ...bodyParams } = params;

    const url = `${baseUrl}/change-detected`;

    return client.post(
        url,
        {
            latitude: bodyParams.latitude,
            longitude: bodyParams.longitude,
            date: moment(),
            images: bodyParams.images
        },
        {
            'x-api-key': apiKey
        }
    );
};

const runPendingReplayBooks = (client) => {
    const url = `${baseUrl}/replay-books/run`;

    return client.put(url);
};

export default client => ({
    createChangeDetected: params => createChangeDetected(client, params),
    runPendingReplayBooks: () => runPendingReplayBooks(client)
});
