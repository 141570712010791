import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 8rem'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20rem'
    },
    title: {
        color: theme.palette.text.link,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
        '&:nth-child(1)': {
            marginTop: 0
        }
    },
    floatingContainer: {
        marginBottom: '0.25rem !important'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem'
    },
    submitButton: {
        marginLeft: theme.spacing(1)
    },
    territoriesContainer: {
        width: '25rem',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    }
}));
