import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    actionsContainer: {
        marginTop: theme.spacing(2)
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalActions: {
        margin: 0,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem',
        width: '100%'
    },
    dialogContainerLarge: {
        minWidth: '45rem',
        minHeight: '75vh',
        maxHeight: '75vh'
    },
    dialogContainerLargeMobile: {
        maxWidth: '100vw'
    },
    dialogContainerSmall: {
        minWidth: '45rem'
    },
    title: {
        fontWeight: '500',
        color: theme?.text?.primary,
        marginTop: theme.spacing(2)
    },
    dialogContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));
