import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    disabled: {
        color: theme.palette.cityooText
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.5rem 0',
        minHeight: '3.4375rem'
    }
}));
