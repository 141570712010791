import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { actions as countriesActions, selectors as countriesSelectors } from '../../../../redux/territories/countries';
import { actions as districtsActions, selectors as districtsSelectors } from '../../../../redux/territories/districts';
import { actions as countiesActions, selectors as countiesSelectors } from '../../../../redux/territories/counties';
import { actions as parishesActions, selectors as parishesSelectors } from '../../../../redux/territories/parishes';
import { actions as selectedOptionsActions, selectors as selectedOptionsSelectors } from '../../../../redux/territories/selected-options';
import { actions as formTenantActions, selectors as formTenantSelectors } from '../../../../redux/form/tenant';

import BaseAutoComplete from '../../../base-auto-complete/BaseAutoComplete';
import TerritoryItem from './territory-item';

import { getSubmitTerritory, validateSubmit } from './helper';
import useStyles from './Terrotories.styles';

const propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onModifiedStep: PropTypes.func.isRequired
};

const Territories = ({ onPrevious, onNext, onModifiedStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state props
    const countries = useSelector(countriesSelectors.getCountries);
    const districts = useSelector(districtsSelectors.getDistricts);
    const counties = useSelector(countiesSelectors.getCounties);
    const parishes = useSelector(parishesSelectors.getParishes);
    const selectedCountry = useSelector(selectedOptionsSelectors.getSelectedCountry);
    const selectedDistrict = useSelector(selectedOptionsSelectors.getSelectedDistrict);
    const selectedCounty = useSelector(selectedOptionsSelectors.getSelectedCounty);
    const selectedParish = useSelector(selectedOptionsSelectors.getSelectedParish);
    const territories = useSelector(formTenantSelectors.getTerritories);
    const territoriesDescription = useSelector(formTenantSelectors.getTerritoriesDescription);

    useEffect(() => {
        dispatch(countriesActions.getCountries());
    }, [dispatch]);

    const handleChangeAutoComplete = useCallback(
        type => (_e, value) => {
            if (!value || !value.id) {
                return null;
            }

            onModifiedStep(true);

            switch (type) {
                case 'country':
                    dispatch(districtsActions.reset());
                    dispatch(countiesActions.reset());
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedDistrict());
                    dispatch(selectedOptionsActions.resetSelectedCounty());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedCountry(value));
                    dispatch(districtsActions.getDistricts(value.id));
                    break;

                case 'district':
                    dispatch(countiesActions.reset());
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedCounty());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedDistrict(value));
                    dispatch(countiesActions.getCounties(value.id));
                    break;

                case 'county':
                    dispatch(parishesActions.reset());
                    dispatch(selectedOptionsActions.resetSelectedParish());
                    dispatch(selectedOptionsActions.setSelectedCounty(value));
                    dispatch(parishesActions.getParishes(value.id));
                    break;

                case 'parish':
                    dispatch(selectedOptionsActions.setSelectedParishes(value));
                    break;

                default:
                    break;
            }

            return null;
        },
        [dispatch, onModifiedStep]
    );

    const handleSubmitTerritory = useCallback(() => {
        if (validateSubmit({ selectedCountry, selectedDistrict, selectedCounty, selectedParish, territories })) {
            dispatch(formTenantActions.submitTerritory(getSubmitTerritory({ selectedCountry, selectedDistrict, selectedCounty, selectedParish })));
        }
    }, [selectedDistrict, selectedCountry, selectedCounty, selectedParish, territories, dispatch]);

    return (
        <>
            <div className={classes.container}>
                <Typography variant="h3" className={classes.title}>
                    {t('territories').toUpperCase()}
                </Typography>
                <BaseAutoComplete
                    options={countries}
                    selectedOption={selectedCountry}
                    optionLabel="name"
                    id="country"
                    onChange={handleChangeAutoComplete}
                />
                <BaseAutoComplete
                    disabled={!selectedCountry.name || isEmpty(districts)}
                    options={districts}
                    selectedOption={selectedDistrict}
                    optionLabel="name"
                    id="district"
                    onChange={handleChangeAutoComplete}
                />
                <BaseAutoComplete
                    disabled={!selectedDistrict.name || isEmpty(counties)}
                    options={counties}
                    selectedOption={selectedCounty}
                    optionLabel="name"
                    id="county"
                    onChange={handleChangeAutoComplete}
                />
                <BaseAutoComplete
                    disabled={!selectedCounty.name || isEmpty(parishes)}
                    options={parishes}
                    selectedOption={selectedParish}
                    optionLabel="name"
                    id="parish"
                    onChange={handleChangeAutoComplete}
                />
                <div className={classes.submitContainer}>
                    <Button
                        startIcon={<AddIcon />}
                        disabled={
                            !selectedCountry.name ||
                            !validateSubmit({ selectedCountry, selectedDistrict, selectedCounty, selectedParish, territories })
                        }
                        color="primary"
                        variant="contained"
                        onClick={handleSubmitTerritory}
                    >
                        {t('add')}
                    </Button>
                </div>
                <div className={classes.territoriesContainer}>
                    {territoriesDescription.map(territory => (
                        <TerritoryItem key={territory.index} territory={territory} />
                    ))}
                </div>
            </div>
            <div className={classes.actions}>
                <Button onClick={onPrevious} color="secondary" variant="contained">
                    {t('previous')}
                </Button>
                <Button onClick={onNext} disabled={territories.length === 0} variant="outlined" className={classes.submitButtonForward}>
                    {t('forward')}
                </Button>
            </div>
        </>
    );
};

Territories.propTypes = propTypes;

export default Territories;
