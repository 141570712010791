import * as Yup from 'yup';

const formOptions = {
    value: {
        required: false
    }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        value: Yup.string()
    });
};

export default {
    formOptions,
    getValidationSchema
};
