import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const runPendingReplayBooks = () => {
    return async (dispatch, _state, { apiSdk }) => {
        dispatch({ type: actionTypes.RUN_PENDING_REPLAY_BOOKS_START });

        try {
            await apiSdk.spatial.runPendingReplayBooks();

            dispatch({ type: actionTypes.RUN_PENDING_REPLAY_BOOKS_SUCCESS });
            dispatch(notificationsActions.openSuccessNotification('success.runPendingReplayBooks'));
        } catch (error) {
            dispatch({ type: actionTypes.RUN_PENDING_REPLAY_BOOKS_FAIL });
            dispatch(notificationsActions.openSuccessNotification('error.runPendingReplayBooks'));
        }
    };
};
