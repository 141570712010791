import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../../modal-manager/actionTypes';

import { actions as tenantsActions } from '../../tenants';
import { actions as notificationsActions } from '../../notifications';

export const submitIdentificationInfo = values => ({
    type: actionTypes.SUBMIT_IDENTIFICATION_INFO,
    payload: values
});

export const submitContactPersomInfo = values => ({
    type: actionTypes.SUBMIT_CONTACT_PERSON_INFO,
    payload: values
});

export const submitSubscription = subscriptionData => ({
    type: actionTypes.SUBMIT_SUBSCRIPTION,
    payload: subscriptionData
});

export const submitTerritory = territory => ({
    type: actionTypes.SUBMIT_TERRITORY,
    payload: territory
});

export const removeTerritory = territory => ({
    type: actionTypes.REMOVE_TERRITORY,
    payload: territory
});

export const insertTenant = tenantToSubmit => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_TENANT_START });

    const state = getState();

    try {
        const response = await apiSdk.tenants.insert(tenantToSubmit);
        const { data } = response;

        dispatch({ type: modalActionTypes.CLOSE_CREATE_TENANT });
        dispatch({ type: actionTypes.SUBMIT_TENANT_SUCCESS, payload: data });
        dispatch(tenantsActions.getTenants({ limit: state.tenants.tenants?.limit, page: state.tenants.tenants?.page }));
        dispatch(notificationsActions.openSuccessNotification('success.createTenant'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.createTenant'));
        dispatch({ type: actionTypes.SUBMIT_TENANT_FAIL });
    }
};

export const updateTenant = (tenantToSubmit, id) => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: actionTypes.SUBMIT_TENANT_START });

    const state = getState();

    try {
        const response = await apiSdk.tenants.update(id, tenantToSubmit);
        const { data } = response;

        dispatch({ type: modalActionTypes.CLOSE_CREATE_TENANT });
        dispatch({ type: actionTypes.SUBMIT_TENANT_SUCCESS, payload: data });
        dispatch(tenantsActions.getTenants({ limit: state.tenants.tenants?.limit, page: state.tenants.tenants?.page }));
        dispatch(notificationsActions.openSuccessNotification('success.updateTenant'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.updateTenant'));
        dispatch({ type: actionTypes.SUBMIT_TENANT_FAIL });
    }
};

export const getTenant = tenant => async (dispatch, _getState, { apiSdk }) => {
    dispatch({ type: actionTypes.RESET });
    dispatch({ type: actionTypes.GET_TENANT_START });

    try {
        const response = await apiSdk.tenants.get(tenant.id);
        const { data } = response;

        dispatch({ type: actionTypes.GET_TENANT_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchTenant'));
        dispatch({ type: actionTypes.GET_TENANT_FAIL });
    }
};
