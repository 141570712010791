import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20rem'
    }
}));
