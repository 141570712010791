import * as Yup from 'yup';

const formOptions = {
    name: {
        required: true
    },
    legalName: {
        required: true
    },
    slug: {
        required: true
    },
    vatNumber: {
        required: true
    },
    street: {
        required: true
    },
    door: {
        required: false
    },
    floor: {
        required: false
    },
    postalCode: {
        required: true
    },
    town: {
        required: true
    },
    comments: {
        required: false,
        multiline: true
    }
};

const getValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required('mandatory_field'),
        legalName: Yup.string().required('mandatory_field'),
        slug: Yup.string()
            .required('mandatory_field')
            .matches(/^[a-zA-Z0-9\-_]+$/, 'slug_validation'),
        vatNumber: Yup.string().min(0).max(20).required('mandatory_field'),
        street: Yup.string().required('mandatory_field'),
        door: Yup.string().max(20).optional(),
        floor: Yup.string().max(20).optional(),
        postalCode: Yup.string().min(0).max(50).required('mandatory_field'),
        town: Yup.string().required('mandatory_field'),
        comments: Yup.string().optional()
    });
};

export default {
    formOptions,
    getValidationSchema
};
