import { get } from 'lodash';
import { combineReducers } from 'redux';
import moment from 'moment';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';
import * as modalManagerActionTypes from '../../modal-manager/actionTypes';
import { TERRITORIES_TYPES } from '../../../components/modals/create-tenant/territories/enumerators';

const fieldInitialState = '';

const initialStateIdentification = {
    name: '',
    legalName: '',
    slug: '',
    vatNumber: '',
    street: '',
    door: '',
    floor: '',
    postalCode: '',
    town: '',
    comments: ''
};

const initalStateContactPerson = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
};

const initialStateSubscription = {
    startDate: moment(),
    finishDate: moment().add(1, 'days'),
    value: ''
};

const initialState = {
    isLoading: false,
    identification: initialStateIdentification,
    contactPerson: initalStateContactPerson,
    territories: [],
    subscription: initialStateSubscription
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_TENANT_START:
        case actionTypes.GET_TENANT_START:
            return true;
        case actionTypes.GET_TENANT_SUCCESS:
        case actionTypes.GET_TENANT_FAIL:
        case actionTypes.SUBMIT_TENANT_SUCCESS:
        case actionTypes.SUBMIT_TENANT_FAIL:
            return false;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const identification = (state = initialState.identification, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_IDENTIFICATION_INFO:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.GET_TENANT_SUCCESS: {
            const { name, legalName, slug, vatNumber, street, door, floor, postalCode, town, comments } = action.payload;

            return {
                name: name ?? fieldInitialState,
                legalName: legalName ?? fieldInitialState,
                slug: slug ?? fieldInitialState,
                vatNumber: vatNumber ?? fieldInitialState,
                street: street ?? fieldInitialState,
                door: door ?? fieldInitialState,
                floor: floor ?? fieldInitialState,
                postalCode: postalCode ?? fieldInitialState,
                town: town ?? fieldInitialState,
                comments: comments ?? fieldInitialState
            };
        }
        case modalManagerActionTypes.CLOSE_CREATE_TENANT:
        case actionTypes.RESET:
            return initialState.identification;
        default:
            return state;
    }
};

export const contactPerson = (state = initialState.contactPerson, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_CONTACT_PERSON_INFO:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.GET_TENANT_SUCCESS: {
            const { contactUser } = action.payload;
            return {
                firstName: contactUser?.firstName ?? fieldInitialState,
                lastName: contactUser?.lastName ?? fieldInitialState,
                email: contactUser?.email ?? fieldInitialState,
                phoneNumber: contactUser?.phoneNumber ?? fieldInitialState
            };
        }
        case modalManagerActionTypes.CLOSE_CREATE_TENANT:
        case actionTypes.RESET:
            return initialState.contactPerson;
        default:
            return state;
    }
};

export const territories = (state = initialState.territories, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_TERRITORY:
            if (action.payload.level === TERRITORIES_TYPES.COUNTRY) {
                return [...state.filter(territory => !territory.country || territory.country.id !== action.payload.country.id), action.payload];
            }

            if (action.payload.level === TERRITORIES_TYPES.DISTRICT) {
                return [...state.filter(territory => !territory.district || territory.district.id !== action.payload.district.id), action.payload];
            }

            if (action.payload.level === TERRITORIES_TYPES.COUNTY) {
                return [...state.filter(territory => !territory.county || territory.county.id !== action.payload.county.id), action.payload];
            }

            return [...state, action.payload];
        case actionTypes.REMOVE_TERRITORY:
            return state.filter((_territory, index) => index !== action.payload.index);

        case actionTypes.GET_TENANT_SUCCESS:
            return action.payload.territories;
        case modalManagerActionTypes.CLOSE_CREATE_TENANT:
        case actionTypes.RESET:
            return initialState.territories;
        default:
            return state;
    }
};

export const subscription = (state = initialState.subscription, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.SUBMIT_SUBSCRIPTION:
            return action.payload;
        case actionTypes.GET_TENANT_SUCCESS: {
            const { subscriptionStartDate, subscriptionEndDate, subscriptionValue } = action.payload;

            return {
                startDate: subscriptionStartDate ?? initialStateSubscription.startDate,
                finishDate: subscriptionEndDate ?? initialStateSubscription.finishDate,
                value: subscriptionValue ?? fieldInitialState
            };
        }
        case modalManagerActionTypes.CLOSE_CREATE_TENANT:
        case actionTypes.RESET:
            return initialState.subscription;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getIdentification = state => get(state, 'identification', initialState.identification);

export const getContactPerson = state => get(state, 'contactPerson', initialState.contactPerson);

export const getSubscription = state => get(state, 'subscription', initialState.subscription);

export const getTerritories = state => get(state, 'territories', initialState.territories);

const reducer = combineReducers({
    isLoading,
    identification,
    contactPerson,
    territories,
    subscription
});

export default reducer;
