import { get } from 'lodash';
import { combineReducers } from 'redux';
import { DEFAULT_ACTION } from '../constants';

import * as actionTypes from './actionTypes';

const initialState = {
    createTenant: {
        isOpen: false,
        data: null
    },
    showTenant: {
        isOpen: false
    },
    confirmModal: {
        isOpen: false,
        data: null
    },
    unfulfilledModal: {
        isOpen: false,
        feature: ''
    }
};

export const createTenant = (state = initialState.createTenant, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CREATE_TENANT:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CREATE_TENANT:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.createTenant;
        default:
            return state;
    }
};

export const showTenant = (state = initialState.showTenant, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_SHOW_TENANT:
            return { isOpen: true };
        case actionTypes.CLOSE_SHOW_TENANT:
            return { isOpen: false };
        case actionTypes.RESET:
            return initialState.showTenant;
        default:
            return state;
    }
};

export const confirmModal = (state = initialState.confirmModal, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_CONFIRM_MODAL:
            return { data: { ...action.payload }, isOpen: true };
        case actionTypes.CLOSE_CONFIRM_MODAL:
            return { ...state, data: null, isOpen: false };
        case actionTypes.RESET:
            return initialState.confirmModal;
        default:
            return state;
    }
};

export const unfulfilledModal = (state = initialState.unfulfilledModal, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.OPEN_UNFULFILLED_MODAL:
            return { isOpen: true, feature: action.payload };
        case actionTypes.CLOSE_UNFULFILLED_MODAL:
            return { isOpen: false };
        case actionTypes.RESET:
            return initialState.unfulfilledModal;
        default:
            return state;
    }
};

export const getCreateTenant = state => get(state, 'createTenant', initialState.createTenant);

export const getShowTenant = state => get(state, 'showTenant', initialState.showTenant);

export const getConfirmModal = state => get(state, 'confirmModal', initialState.confirmModal);

export const getUnfulfilledModal = state => get(state, 'unfulfilledModal', initialState.unfulfilledModal);

const reducer = combineReducers({
    createTenant,
    showTenant,
    confirmModal,
    unfulfilledModal
});

export default reducer;
