/* eslint-disable quotes */
import { createMuiTheme } from '@material-ui/core';
import defaultTheme from './default';

const overrides = {
    typography: {
        fontFamily: `'Hind', sans-serif`,
        htmlFontSize: 16,
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'bold',
            fontSize: '6rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        h2: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1.5625rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        h3: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        h4: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        h5: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        h6: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        subtitle1: {
            fontFamily: `'Hind', sans-serif`,
            fontSize: '1.125rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.16875rem'
        },
        subtitle2: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 500,
            fontSize: '0.875rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.13125rem'
        },
        body1: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.375rem',
            letterSpacing: 'normal'
        },
        body2: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '1.125rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.5625rem',
            letterSpacing: 'normal'
        },
        button: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: '500',
            fontSize: '1rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        caption: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '0.75rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal'
        },
        overline: {
            fontFamily: `'Hind', sans-serif`,
            fontWeight: 'normal',
            fontSize: '0.75rem',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'uppercase'
        }
    }
};

export default {
    default: createMuiTheme({ ...defaultTheme, ...overrides })
};
