import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const createChangeDetected = data => {
    return async (dispatch, _state, { apiSdk }) => {
        dispatch({ type: actionTypes.CREATE_CHANGE_DETECTED_START });

        try {
            await apiSdk.spatial.createChangeDetected(data);

            dispatch(notificationsActions.openSuccessNotification('success.createChangeDetected'));
            dispatch({ type: actionTypes.CREATE_CHANGE_DETECTED_SUCCESS });
        } catch (error) {
            dispatch(notificationsActions.openErrorNotification('error.createChangeDetected'));
            dispatch({ type: actionTypes.CREATE_CHANGE_DETECTED_FAIL });
        }
    };
};
