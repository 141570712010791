import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import config from '../../_config';
import { actions as notificationsActions, selectors as notificationsSelectors } from '../../redux/notifications';

const propTypes = {
    children: PropTypes.node.isRequired
};

const SlideTransition = props => <Slide direction="down" {...props} />;

const BaseNotifications = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isNotificationOpen = useSelector(notificationsSelectors.isNotificationOpen);
    const notification = useSelector(notificationsSelectors.getNotification);

    const handleClose = () => {
        dispatch(notificationsActions.closeNotification());
    };

    return (
        <>
            <Snackbar
                TransitionComponent={SlideTransition}
                open={isNotificationOpen}
                autoHideDuration={config.notificationsDefaultTime}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <Alert variant="filled" severity={notification.severity}>
                    {t(notification.message)}
                </Alert>
            </Snackbar>
            {children}
        </>
    );
};

BaseNotifications.propTypes = propTypes;

export default BaseNotifications;
