/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import config from '../../../_config';
import { RESET_ALL } from '../../../redux/constants';
import configureStore from '../../../redux';

const MAX_RETRY_COUNT = 3;
let pendingJobs = [];
let isRefreshing = false;

const inflateTokens = () => {
    const savedTokens = localStorage.getItem(config.TOKENS);

    if (savedTokens) {
        try {
            const tokens = JSON.parse(savedTokens);
            if (tokens && tokens.token && tokens.refreshToken) {
                return tokens;
            }
        } catch (err) {
            console.log('Failed to inflate user tokens');
        }
    }
    return null;
};

const retryRequest = originalRequest => {
    const inflatedTokens = inflateTokens();

    originalRequest.headers = {
        Authorization: ['Bearer', inflatedTokens.token].join(' ')
    };
    originalRequest.retryCount = (originalRequest.retryCount || 0) + 1;

    if (originalRequest.retryCount > MAX_RETRY_COUNT) {
        throw new Error('reached max retry count');
    }

    try {
        if (originalRequest.data) {
            const data = JSON.parse(originalRequest.data);
            if (data) {
                originalRequest.data = data;
            }
        }
    } catch (err) {
        console.log('Failed to parse original request data');
    }

    return axios.request(originalRequest);
};

const requestRefreshToken = savedRefreshToken => {
    return axios
        .post(`${config.apiBaseUrl}/auth/refresh`, {
            refreshToken: savedRefreshToken
        })
        .then(response => {
            return response.data.data;
        });
};

const performRefreshFlow = (savedRefreshToken, originalRequest) => {
    return requestRefreshToken(savedRefreshToken)
        .then(newAuth => {
            if (!newAuth || !newAuth.token || !newAuth.refreshToken) {
                throw new Error('Invalid refresh response');
            }

            localStorage.setItem(config.TOKENS, JSON.stringify({ token: newAuth.token, refreshToken: newAuth.refreshToken }));

            return retryRequest(originalRequest);
        })
        .catch(retryError => {
            localStorage.removeItem(config.TOKENS);
            configureStore.dispatch({ type: RESET_ALL });

            throw retryError;
        });
};

const setupAxios = axiosClient => {
    axiosClient.interceptors.response.use(null, error => {
        if (error.response && error.response.status === 401) {
            const inflatedTokens = inflateTokens();
            if (inflatedTokens && inflatedTokens.refreshToken) {
                const token = inflatedTokens.refreshToken;
                if (!isRefreshing) {
                    isRefreshing = true;

                    return performRefreshFlow(token, error.config)
                        .then(response => {
                            isRefreshing = false;

                            // Continue pending jobs
                            if (pendingJobs.length > 0) {
                                pendingJobs.forEach(job => job.start());
                            }

                            pendingJobs = [];

                            return response;
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 401) {
                                localStorage.removeItem(config.TOKENS);
                                configureStore.dispatch({ type: RESET_ALL });
                            } else {
                                return Promise.reject(err);
                            }
                        });
                }
                const jobPromise = new Promise((res, rej) => {
                    pendingJobs.push({
                        start: res,
                        cancel: rej
                    });
                });

                return jobPromise.then(() => retryRequest(error.config)).catch(() => Promise.reject(error));
            }
            localStorage.removeItem(config.TOKENS);
            configureStore.dispatch({ type: RESET_ALL });
        }
        return Promise.reject(error);
    });
};

const create = () => {
    const client = {};

    const tokens = inflateTokens();

    const headers = {
        'Content-Type': 'application/json'
    };
    if (tokens) {
        const { token } = tokens;
        headers.Authorization = `Bearer ${token}`;
    }
    const axiosClient = axios.create({
        baseURL: `${config.apiBaseUrl}`,
        headers
    });

    setupAxios(axiosClient, () => {});

    axiosClient.interceptors.request.use(
        configuration => {
            const newConfiguration = configuration;

            const requestHeaders = {
                'Content-Type': 'application/json'
            };

            if (configuration['x-api-key']) {
                newConfiguration.baseURL = `${config.apiExternalUrl}`;
                requestHeaders['x-api-key'] = configuration['x-api-key'];
            } else {
                newConfiguration.baseURL = `${config.apiBaseUrl}`;

                if (tokens) {
                    const { token } = tokens;
                    requestHeaders.Authorization = `Bearer ${token}`;
                }
            }

            newConfiguration.headers = requestHeaders;

            return newConfiguration;
        },
        error => Promise.reject(error)
    );

    ['get', 'del', 'post', 'put', 'patch'].forEach(method => {
        // eslint-disable-next-line func-names
        client[method] = function (...args) {
            return axiosClient[method](...args)
                .catch(error => {
                    throw error;
                })
                .then(response => {
                    return response.data;
                });
        };
    });

    return client;
};

export default {
    create
};
