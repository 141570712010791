import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.RUN_PENDING_REPLAY_BOOKS_SUCCESS:
        case actionTypes.RUN_PENDING_REPLAY_BOOKS_FAIL:
            return false;
        case actionTypes.RUN_PENDING_REPLAY_BOOKS_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

const reducer = combineReducers({
    isLoading
});

export default reducer;
