import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { actions as changeDetecedActions, selectors as changeDetecedSelectors } from '../../../redux/spatial/change-detected';
import { actions as replayBooksActions, selectors as replayBooksSelectors } from '../../../redux/spatial/replay-books';

import BaseInput, { BaseInputLatitude, BaseInputLongitude } from '../../../components/base-input';
import Loading from '../../../components/base-loading';

import useStyles from './Simulation.styles';

const initialStateSatellite = {
    apiKey: '',
    latitude: '',
    longitude: '',
    images: []
};

const Simulation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fields, setFields] = useState(initialStateSatellite);
    const [newImage, setNewImage] = useState('');

    const isLoadingCreateChangeDetect = useSelector(changeDetecedSelectors.getIsLoading);
    const isLoadingProcessReplayBooks = useSelector(replayBooksSelectors.getIsLoading);
    const cleanFields = useSelector(changeDetecedSelectors.getCleanFields);

    useEffect(() => {
        setFields(initialStateSatellite);
    }, [cleanFields]);

    const handleChangeField = useCallback(
        key => e => {
            setFields({ ...fields, [key]: e.target.value });
        },
        [setFields, fields]
    );

    const handleChangeImage = useCallback(
        e => {
            setNewImage(e.target.value);
        },
        [setNewImage]
    );

    const handleInsertNewImage = useCallback(() => {
        setFields({
            ...fields,
            images: [...fields.images, newImage]
        });
        setNewImage('');
    }, [setFields, fields, newImage, setNewImage]);

    const handleRemoveImage = useCallback(
        indexToRemove => () => {
            setFields({
                ...fields,
                images: fields.images.filter((_image, index) => index !== indexToRemove)
            });
        },
        [fields, setFields]
    );

    const handleSubmitSatelliteInfo = useCallback(() => {
        dispatch(changeDetecedActions.createChangeDetected(fields));
    }, [dispatch, fields]);

    const handleProcessReplayBooks = useCallback(() => {
        dispatch(replayBooksActions.runPendingReplayBooks());
    }, [dispatch]);

    if (isLoadingCreateChangeDetect || isLoadingProcessReplayBooks) {
        return (
            <Box minHeight="20rem" display="flex" justifyContent="center" width="100%">
                <Typography className={classes.title} variant="h3">
                    {t('satellite.simulation')}
                </Typography>
                <Loading />
            </Box>
        );
    }

    return (
        <>
            <Box display="flex" flexDirection="column" flex="1" justifyContent="space-between">
                <Box>
                    <Typography className={classes.title} variant="h3">
                        {t('satellite.simulation')}
                    </Typography>
                    <BaseInput
                        key="apiKey"
                        placeholder={t('satellite.apiKey')}
                        styleClasses={{
                            root: classes.inputRoot
                        }}
                        value={fields.apiKey}
                        onChange={handleChangeField('apiKey')}
                    />
                    <Typography className={classnames(classes.title, classes.coordinatesTitle)} variant="h3">
                        {t('satellite.coordinates')}
                    </Typography>
                    <Box display="flex">
                        <BaseInputLatitude
                            classes={{
                                root: classes.inputRoot
                            }}
                            label=""
                            value={fields.latitude}
                            onChange={handleChangeField('latitude')}
                        />
                        <BaseInputLongitude
                            classes={{
                                root: classes.inputRoot
                            }}
                            label=""
                            value={fields.longitude}
                            onChange={handleChangeField('longitude')}
                        />
                    </Box>
                </Box>
                <Button className={classes.actionButton} color="primary" variant="contained" onClick={handleSubmitSatelliteInfo}>
                    {t('satellite.launch').toUpperCase()}
                </Button>
                <Button className={classes.actionButton} color="primary" variant="contained" onClick={handleProcessReplayBooks}>
                    {t('satellite.runReplayBook').toUpperCase()}
                </Button>
            </Box>
            <Box flex="1">
                <Box display="flex">
                    <Typography className={classes.title} variant="h3">
                        {t('satellite.images')}
                    </Typography>
                    <Button
                        startIcon={<AddIcon />}
                        className={classes.addButton}
                        disabled={newImage === ''}
                        color="primary"
                        variant="contained"
                        onClick={handleInsertNewImage}
                    >
                        {t('add')}
                    </Button>
                </Box>
                <BaseInput
                    key="image"
                    placeholder={t('satellite.url')}
                    styleClasses={{
                        root: classes.inputImage
                    }}
                    value={newImage}
                    onChange={handleChangeImage}
                />
                <Box>
                    {fields.images.map((image, index) => (
                        <Box display="flex">
                            <Box className={classes.coordinatesInputs}>
                                <BaseInput
                                    styleClasses={{
                                        root: classes.inputImage
                                    }}
                                    key={image}
                                    required
                                    value={image}
                                    disabled
                                />
                            </Box>
                            <IconButton className={classes.deleteButton} onClick={handleRemoveImage(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box flex="1" />
        </>
    );
};

export default Simulation;
