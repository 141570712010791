const STATUSES = {
    ERROR: {
        code: 0,
        color: '#C00102',
        label: 'monitoring.error'
    },
    SUCCESS: {
        code: 1,
        color: '#1A9982',
        label: 'monitoring.success'
    },
    OTHER: {
        code: 2,
        color: 'transparent',
        label: 'monitoring.other'
    }
};

const getStatusesData = statusId => {
    const statuses = Object.keys(STATUSES).find(statusKey => STATUSES[statusKey].code === statusId);

    return STATUSES[statuses] ?? {};
};

const isValid = status => Object.values(STATUSES).includes(status);

export default {
    STATUSES,
    getStatusesData,
    isValid
};
