const getCountries = client => {
    const url = '/territories/countries';

    return client.get(url, {});
};

const getDistricts = (client, countryId) => {
    const url = '/territories/districts';

    return client.get(url, { params: { countryId } });
};

const getCounties = (client, districtId) => {
    const url = '/territories/counties';

    return client.get(url, { params: { districtId } });
};

const getParishes = (client, countyId) => {
    const url = '/territories/parishes';

    return client.get(url, { params: { countyId } });
};

export default client => ({
    getCountries: () => getCountries(client),
    getDistricts: countryId => getDistricts(client, countryId),
    getCounties: districtId => getCounties(client, districtId),
    getParishes: countyId => getParishes(client, countyId)
});
