export default {
    // PUBLIC
    HOME: '/',
    LOGIN: '/login',
    RECOVER_PASSWORD: '/recover-password',
    RECOVER_PASSWORD_LINK: '/recover-password-confirmation',

    // PRIVATE
    TENANTS: '/tenants',
    SATELLITE: '/satellite',
    MAP: '/map',
    PAYMENT: '/payment',
    DASHBOARD: '/dashboard'
};
