import React from 'react';
import { Box } from '@material-ui/core';

import useStyles from './Satellite.styles';
import Simulation from './simulation';
import Monitoring from './monitoring';

const Satellite = () => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" className={classes.root}>
                <Simulation />
            </Box>
            <Box minHeight="20rem" className={classes.root}>
                <Monitoring />
            </Box>
        </Box>
    );
};

export default Satellite;
