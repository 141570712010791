/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
    LocationCity as LocationCityIcon,
    ArrowBack as ArrowBackIcon,
    Satellite as SatelliteIcon,
    Map as MapIcon,
    Payment as PaymentIcon,
    Dashboard as DashboardIcon
} from '@material-ui/icons';

import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import { useTranslation } from 'react-i18next';
import useStyles from './Sidebar.styles';

// utils
import ROUTES from '../../utils/routes';

// components
import SidebarLink from './sidebar-link';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';

const Sidebar = ({ location }) => {
    const classes = useStyles();
    const theme = useTheme();

    // global
    const { isSidebarOpened } = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const { t } = useTranslation();

    // local
    const [isPermanent, setPermanent] = useState(true);

    const structure = [
        {
            label: t('sidebar.map'),
            link: ROUTES.MAP,
            icon: <MapIcon />
        },
        {
            label: t('sidebar.tenants'),
            link: ROUTES.TENANTS,
            icon: <LocationCityIcon />
        },
        {
            label: t('sidebar.dashboard'),
            link: ROUTES.DASHBOARD,
            icon: <DashboardIcon />
        },
        {
            label: t('sidebar.payment'),
            link: ROUTES.PAYMENT,
            icon: <PaymentIcon />
        },
        {
            label: t('sidebar.satellite'),
            link: ROUTES.SATELLITE,
            icon: <SatelliteIcon />
        }
    ];

    const handleWindowWidthChange = () => {
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme.breakpoints.values.sm;
        const isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowWidthChange);
        handleWindowWidthChange();

        return () => {
            window.removeEventListener('resize', handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? 'permanent' : 'temporary'}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened
                })
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse)
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink key={link.label} location={location} isSidebarOpened={isSidebarOpened} {...link} />
                ))}
            </List>
        </Drawer>
    );
};

export default withRouter(Sidebar);
