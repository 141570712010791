import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 8rem'
    },
    title: {
        color: theme?.palette?.text?.link,
        marginTop: theme.spacing(1)
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingTop: theme.spacing(4),
        minHeight: '0.75rem'
    },
    submitButton: {
        marginLeft: theme.spacing(1)
    }
}));
