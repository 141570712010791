import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    cleanFields: false
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.CREATE_CHANGE_DETECTED_SUCCESS:
        case actionTypes.CREATE_CHANGE_DETECTED_FAIL:
            return false;
        case actionTypes.CREATE_CHANGE_DETECTED_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const cleanFields = (state = initialState.cleanFields, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.CREATE_CHANGE_DETECTED_SUCCESS:
            return !state;
        case actionTypes.CREATE_CHANGE_DETECTED_FAIL:
            return state;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getCleanFields = state => get(state, 'cleanFields', initialState.cleanFields);

const reducer = combineReducers({
    isLoading,
    cleanFields
});

export default reducer;
