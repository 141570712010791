import { get } from 'lodash/fp';

import * as fromReducer from './reducer';

const getModalManagerState = get('modalManager');

export const getCreateTenant = state => fromReducer.getCreateTenant(getModalManagerState(state));

export const getShowTenant = state => fromReducer.getShowTenant(getModalManagerState(state));

export const getConfirmModal = state => fromReducer.getConfirmModal(getModalManagerState(state));

export const getUnfulfilledModal = state => fromReducer.getUnfulfilledModal(getModalManagerState(state));
