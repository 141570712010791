import { TERRITORIES_TYPES } from './enumerators';

const getLevel = selectedFields => {
    const { selectedCountry, selectedDistrict, selectedCounty, selectedParish } = selectedFields;

    if (selectedParish.name) {
        return TERRITORIES_TYPES.PARISH;
    }

    if (selectedCounty.name) {
        return TERRITORIES_TYPES.COUNTY;
    }

    if (selectedDistrict.name) {
        return TERRITORIES_TYPES.DISTRICT;
    }

    if (selectedCountry.name) {
        return TERRITORIES_TYPES.COUNTRY;
    }

    return '';
};

const getSubmitTerritory = selectedFields => {
    const { selectedCountry, selectedDistrict, selectedCounty, selectedParish } = selectedFields;

    return {
        level: getLevel(selectedFields),
        country: selectedCountry.name ? selectedCountry : null,
        district: selectedDistrict.name ? selectedDistrict : null,
        county: selectedCounty.name ? selectedCounty : null,
        parish: selectedParish.name ? selectedParish : null
    };
};

const existCountry = (selectedCountry, territories) =>
    territories.filter(territory => territory.level === TERRITORIES_TYPES.COUNTRY).some(territory => territory.country.id === selectedCountry.id);

const existDistrict = (selectedDistrict, territories) =>
    territories.filter(territory => territory.level === TERRITORIES_TYPES.DISTRICT).some(territory => territory.district.id === selectedDistrict.id);

const existCounty = (selectedCounty, territories) =>
    territories.filter(territory => territory.level === TERRITORIES_TYPES.COUNTY).some(territory => territory.county.id === selectedCounty.id);

const existParish = (selectedParish, territories) =>
    territories.filter(territory => territory.level === TERRITORIES_TYPES.PARISH).some(territory => territory.parish.id === selectedParish.id);

const validateSubmit = selectedFields => {
    const { selectedCountry, selectedDistrict, selectedCounty, selectedParish, territories } = selectedFields;

    const level = getLevel(selectedFields);

    if (level === TERRITORIES_TYPES.PARISH) {
        if (
            existCountry(selectedCountry, territories) ||
            existDistrict(selectedDistrict, territories) ||
            existCounty(selectedCounty, territories) ||
            existParish(selectedParish, territories)
        ) {
            return false;
        }
    }

    if (level === TERRITORIES_TYPES.COUNTY) {
        if (existCountry(selectedCountry, territories) || existDistrict(selectedDistrict, territories) || existCounty(selectedCounty, territories)) {
            return false;
        }
    }

    if (level === TERRITORIES_TYPES.DISTRICT) {
        if (existCountry(selectedCountry, territories) || existDistrict(selectedDistrict, territories)) {
            return false;
        }
    }

    if (level === TERRITORIES_TYPES.COUNTRY) {
        if (existCountry(selectedCountry, territories)) {
            return false;
        }
    }

    return true;
};

export { getSubmitTerritory, validateSubmit };
