import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';
import { Formik, Form } from 'formik';

import BaseInput from '../../../base-input';
import { actions as formTenantActions, selectors as formTenantSelectors } from '../../../../redux/form/tenant';

import useStyles from './ContactPerson.styles';
import identificationHelper from './helper';

const propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onModifiedStep: PropTypes.func.isRequired
};

const ContactPerson = ({ onNext, onPrevious, onModifiedStep }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    // state props
    const contactPersonInfo = useSelector(formTenantSelectors.getContactPerson);

    const handleSubmitForm = useCallback(
        values => {
            dispatch(formTenantActions.submitContactPersomInfo(values));
            onNext();
        },
        [dispatch, onNext]
    );

    return (
        <Formik initialValues={contactPersonInfo} onSubmit={handleSubmitForm} validationSchema={identificationHelper.getValidationSchema()}>
            {({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
                <Form>
                    <div className={classes.container}>
                        <Typography variant="h3" className={classes.title}>
                            {t('contact_person').toUpperCase()}
                        </Typography>
                        {Object.keys(values).map(fieldKey => (
                            <BaseInput
                                key={fieldKey}
                                label={t(snakeCase(fieldKey))}
                                id={fieldKey}
                                name={fieldKey}
                                required={
                                    identificationHelper.formOptions.hasOwnProperty(fieldKey) && identificationHelper.formOptions[fieldKey].required
                                }
                                multiline={
                                    identificationHelper.formOptions.hasOwnProperty(fieldKey) && identificationHelper.formOptions[fieldKey].multiline
                                }
                                value={values[fieldKey]}
                                helperText={errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey) ? t(errors[fieldKey]) : ''}
                                error={!!(errors.hasOwnProperty(fieldKey) && touched.hasOwnProperty(fieldKey))}
                                onChange={e => {
                                    onModifiedStep(true);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />
                        ))}
                    </div>
                    <div className={classes.actions}>
                        <Button onClick={onPrevious} color="secondary" variant="contained">
                            {t('previous')}
                        </Button>
                        <Button onClick={handleSubmit} variant="outlined" className={classes.submitButton}>
                            {t('forward')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

ContactPerson.propTypes = propTypes;

export default ContactPerson;
