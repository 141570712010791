/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ROUTES from './utils/routes';

// components
import Layout from './components/layout';
import { actions as accountActions, selectors as accountSelectors } from './redux/account';

// pages
import Error from './pages/error';
import Login from './pages/login';
import PasswordRecovery from './pages/password-recovery';
import PasswordReset from './pages/password-reset';
import config from './_config';

export default function App() {
    const tokens = JSON.parse(localStorage.getItem(config.TOKENS));
    const dispatch = useDispatch();

    // state props
    const isAuthenticated = useSelector(accountSelectors.isAuthenticated);

    useEffect(() => {
        if (tokens) {
            dispatch(accountActions.getMe());
        }
    }, [dispatch, tokens]);

    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path={ROUTES.LOGIN} component={Login} />
                <PublicRoute path={ROUTES.RECOVER_PASSWORD_LINK} component={PasswordReset} />
                <PublicRoute path={ROUTES.RECOVER_PASSWORD} component={PasswordRecovery} />
                <PrivateRoute path={ROUTES.HOME} component={Layout} />
                <Route component={Error} />
            </Switch>
        </BrowserRouter>
    );

    // #######################################################################

    function PrivateRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated || tokens ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    )
                }
            />
        );
    }

    function PublicRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        <Redirect
                            to={{
                                pathname: '/'
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
}
