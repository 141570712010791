import * as actionTypes from './actionTypes';

import { actions as notificationsActions } from '../../notifications';

export const getLogs = params => {
    return async (dispatch, _state, { apiSdk }) => {
        dispatch({ type: actionTypes.GET_ACTIVITY_LOGS_START });

        try {
            const response = await apiSdk.activityLogs.find(params);
            const { data } = response;

            dispatch({ type: actionTypes.GET_ACTIVITY_LOGS_SUCCESS, payload: data });
        } catch (error) {
            dispatch(notificationsActions.openErrorNotification('error.fetchLogs'));
            dispatch({ type: actionTypes.GET_ACTIVITY_LOGS_FAIL });
        }
    };
};

export const setPage = page => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: page
});
