import { combineReducers } from 'redux';

import changeDetecedReducer from './change-detected';
import activityLogsReducer from './activity-logs';
import replayBooksReducer from './replay-books';

const reducer = combineReducers({
    changeDeteced: changeDetecedReducer,
    activityLogs: activityLogsReducer,
    replayBooks: replayBooksReducer
});

export default reducer;
