import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.5rem 0',
        minHeight: '3.4375rem'
    },
    root: {
        background: theme.palette.background.white,
        padding: '2rem',
        marginBottom: '2rem',
        borderRadius: '0.625rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1)'
    },
    inputRoot: {
        paddingRight: '2rem',
        width: '100%'
    },
    inputImage: {
        width: '100%'
    },
    actionButton: {
        width: '20rem',
        marginTop: '1rem'
    },
    addButton: {
        height: '1.5rem',
        marginLeft: '2rem'
    },
    coordinatesInputs: {
        display: 'flex',
        flex: 1
    },
    title: {
        height: '2rem',
        fontWeight: 600
    },
    coordinatesTitle: {
        marginTop: '1.2rem'
    }
}));
