import { makeStyles } from '@material-ui/styles';

const drawerWidth = 90;

export default makeStyles(theme => ({
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: 60,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth
        }
    },
    toolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    mobileBackButton: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.only('sm')]: {
            marginTop: theme.spacing(0.625)
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));
