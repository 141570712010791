import { useMediaQuery } from 'react-responsive';

const MediaQuery = ({ Mobile, Tablet, Desktop }) => {
    const isMobile = useMediaQuery({ maxWidth: 599 });
    const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 991 });

    if (isMobile) {
        return Mobile;
    }
    if (isTablet) {
        return Tablet;
    }

    return Desktop;
};

export default MediaQuery;
