import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Close as RemoveIcon } from '@material-ui/icons';

import { actions as formTenantActions } from '../../../../../redux/form/tenant';

import useStyles from './TerritoryItem.styles';

const propTypes = {
    territory: PropTypes.shape({
        description: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired
    }).isRequired,
    readOnly: PropTypes.bool
};

const defaultProps = {
    readOnly: false
};

const TerritoryItem = ({ territory, readOnly }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const description = territory.description.replace('(TERRITORY_TYPE)', `(${t(`${territory.level.toLowerCase()}`)})`);

    const handleRemoveTerritory = useCallback(() => dispatch(formTenantActions.removeTerritory(territory)), [dispatch, territory]);

    return (
        <div className={classes.territory}>
            {!readOnly && (
                <IconButton className={classes.removeIcon} onClick={handleRemoveTerritory}>
                    <RemoveIcon style={{ fontSize: 20 }} />
                </IconButton>
            )}
            <span>{description}</span>
        </div>
    );
};

TerritoryItem.propTypes = propTypes;
TerritoryItem.defaultProps = defaultProps;

export default memo(TerritoryItem);
