import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    statusCell: {
        minWidth: '5.5rem',
        padding: '0 1rem',
        color: 'white',
        borderRadius: '0.3125rem',
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontWeight: theme.typography.fontWeightMedium
    },
    dropdownRoot: {
        margin: theme.spacing(1),
        minWidth: 120
    }
}));
