import * as actionTypes from './actionTypes';
import * as modalActionTypes from '../modal-manager/actionTypes';

import { actions as notificationsActions } from '../notifications';

export const getTenants = params => async (dispatch, _state, { apiSdk }) => {
    dispatch({ type: actionTypes.GET_TENANTS_START });

    try {
        const response = await apiSdk.tenants.find(params);
        const { data } = response;

        dispatch({ type: actionTypes.GET_TENANTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.fetchTenants'));
        dispatch({ type: actionTypes.GET_TENANTS_FAIL });
    }
};

export const reset = () => ({
    type: actionTypes.RESET
});

export const setPage = page => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: page
});

export const blockTenant = id => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });

    const state = getState();

    try {
        await apiSdk.tenants.block(id);

        dispatch(getTenants({ limit: state.tenants.tenants?.limit, page: state.tenants.tenants?.page }));
        dispatch(notificationsActions.openSuccessNotification('success.blockTenant'));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.blockTenant'));
    }
};

export const unblockTenant = id => async (dispatch, getState, { apiSdk }) => {
    dispatch({ type: modalActionTypes.CLOSE_CONFIRM_MODAL });

    const state = getState();

    try {
        await apiSdk.tenants.unblock(id);

        dispatch(notificationsActions.openSuccessNotification('success.unblockTenant'));
        dispatch(getTenants({ limit: state.tenants.tenants?.limit, page: state.tenants.tenants?.page }));
    } catch (error) {
        dispatch(notificationsActions.openErrorNotification('error.unblockTenant'));
    }
};
