import { get } from 'lodash';
import { combineReducers } from 'redux';

import { DEFAULT_ACTION } from '../../constants';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    activityLogs: {
        count: 0,
        limit: 8,
        page: 1,
        pages: 0,
        rows: []
    }
};

export const isLoading = (state = initialState.isLoading, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_ACTIVITY_LOGS_SUCCESS:
        case actionTypes.GET_ACTIVITY_LOGS_FAIL:
            return false;
        case actionTypes.GET_ACTIVITY_LOGS_START:
            return true;
        case actionTypes.RESET:
            return initialState.isLoading;
        default:
            return state;
    }
};

export const activityLogs = (state = initialState.activityLogs, action = DEFAULT_ACTION) => {
    switch (action.type) {
        case actionTypes.GET_ACTIVITY_LOGS_SUCCESS:
            return action.payload;
        case actionTypes.SET_CURRENT_PAGE:
            return { ...state, page: action.payload };
        case actionTypes.GET_ACTIVITY_LOGS_FAIL:
            return initialState.activityLogs;
        default:
            return state;
    }
};

export const getIsLoading = state => get(state, 'isLoading', initialState.isLoading);

export const getActivityLogs = state => get(state, 'activityLogs', initialState.activityLogs);

const reducer = combineReducers({
    isLoading,
    activityLogs
});

export default reducer;
