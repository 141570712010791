/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, Button, DialogActions, useMediaQuery, useTheme, DialogContent, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import useStyles from './BaseDialog.styles';

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    hideHeader: PropTypes.bool,
    openButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    styleClasses: PropTypes.object,
    actions: PropTypes.node,
    children: PropTypes.node.isRequired,
    small: PropTypes.bool,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
    onSubmit: PropTypes.func,
    submitText: PropTypes.string
};

const defaultProps = {
    styleClasses: {},
    actions: null,
    small: false,
    onCancel: null,
    onConfirm: null,
    onSubmit: null,
    hideHeader: false,
    title: '',
    submitText: '',
    cancelText: '',
    closeButtonTitle: '',
    openButtonTitle: ''
};

const BaseDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    hideHeader,
    openButtonTitle,
    closeButtonTitle,
    styleClasses,
    actions,
    small,
    onCancel,
    cancelText,
    onSubmit,
    submitText,
    children,
    ...rest
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getPaperStyle = () => {
        if (small && !fullScreen) {
            return classes.dialogContainerSmall;
        }

        if (!small && !fullScreen) {
            return classes.dialogContainerLarge;
        }

        return classes.dialogContainerLargeMobile;
    };

    const getActions = () => {
        if (onCancel || onSubmit) {
            return (
                <>
                    {onCancel && cancelText && (
                        <Button onClick={onCancel} color="secondary" variant="contained">
                            {cancelText}
                        </Button>
                    )}
                    {onSubmit && submitText && (
                        <Button onClick={onSubmit} color="primary" variant="contained">
                            {submitText}
                        </Button>
                    )}
                </>
            );
        }

        if (actions) {
            return <>{actions}</>;
        }

        return null;
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            classes={{
                paper: getPaperStyle(),
                ...styleClasses
            }}
            {...rest}
        >
            {!hideHeader && (
                <DialogTitle disableTypography className={classes.titleContainer}>
                    <Typography variant="h2" className={classes.title}>
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent>
                <div className={classes.dialogContent}>
                    {children}
                    <DialogActions className={classes.modalActions}>{getActions()}</DialogActions>
                </div>
            </DialogContent>
        </Dialog>
    );
};

BaseDialog.propTypes = propTypes;
BaseDialog.defaultProps = defaultProps;

export default BaseDialog;
